import { equals, mergeRight, reject } from 'ramda';
import {
  INIT_STORE,
  SET_LOCALE,
  SET_ERROR,
  SET_NOTIFICATION,
  ADD_ACTIVITY,
  REMOVE_ACTIVITY,
  SET_SUGGESTION,
  CLEAR_HISTORY_RECORDS,
  ADD_HISTORY_RECORD,
  REMOVE_HISTORY_RECORD,
} from '@/store/mutation-types';
import { STORAGE_KEY } from '@/store/index';
import { persistedStateSpec } from '@/store/state-specs';
import Storage from '@/store/storage';

export default {
  [INIT_STORE](state) {
    if (Storage.getItem(STORAGE_KEY)) {
      var persistedState = persistedStateSpec(JSON.parse(Storage.getItem(STORAGE_KEY)));

      this.replaceState(mergeRight(state, persistedState));
    }
  },

  [SET_LOCALE](state, locale) {
    state.locale = locale;
  },

  [SET_ERROR](state, error) {
    state.error = error;
  },

  [SET_NOTIFICATION](state, notification) {
    state.notification = notification;
  },

  [ADD_ACTIVITY](state, activity) {
    state.activities = [...state.activities, activity];
  },

  [REMOVE_ACTIVITY](state, activity) {
    state.activities = reject(equals(activity), state.activities);
  },

  [SET_SUGGESTION](state, suggestion) {
    state.suggestion = suggestion;
  },

  [ADD_HISTORY_RECORD](state, historyRecord) {
    state.history = [...state.history, historyRecord];
  },

  [REMOVE_HISTORY_RECORD](state, historyRecord) {
    state.history = reject(equals(historyRecord), state.history);
  },

  [CLEAR_HISTORY_RECORDS](state) {
    state.history = [];
  },
};
