export const INIT_STORE = '🌐 Initialise Store';
export const SET_ERROR = '❌ Set Global Error';
export const SET_LOCALE = '🌎 Set Locale';
export const SET_NOTIFICATION = '🔔 Set Notification';

export const ADD_ACTIVITY = '🐬 ➕ Add Activity';
export const REMOVE_ACTIVITY = '🐬 ➖ Remove Activity';

export const SET_SUGGESTION = '🤔 ✅ Set Suggestion';

export const ADD_HISTORY_RECORD = '📜 ➕ Add History Record';
export const REMOVE_HISTORY_RECORD = '📜 ➖ Remove History Record';
export const CLEAR_HISTORY_RECORDS = '📜 ⭕️ Clear History Records';
