// import localforage from 'localforage';

// localforage.config({
//   name: 'what.do'
// });

export default {
  getItem(key) {
    return localStorage.getItem(key);
  },

  setItem(key, value) {
    localStorage.setItem(key, value);
  },

  removeItem(key) {
    localStorage.removeItem(key);
  },
};
