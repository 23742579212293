import Vue from 'vue';
import router from '@/router';
import store from '@/store/index';
import { INIT_STORE, SET_ERROR } from '@/store/mutation-types';
import App from '@/App.vue';
import i18n from '@/i18n';
import Notifications from '@/domain/notifications';
import { buildStoreError } from '@/errors';

// styles
import '@/assets/styles/index.css';

Vue.config.productionTip = false;

Vue.use(Notifications);

var initConfig = {
  router,
  store,
  i18n,
  render: (h) => h(App),

  beforeCreate() {
    // DI
    this.$notifications.setI18n(this.$i18n);

    try {
      this.$store.commit(INIT_STORE);
    } catch (e) {
      this.$store.commit(SET_ERROR, buildStoreError(e));
    }
  },
};

// global error handling when not in debug mode
if (!process.env.VUE_APP_DEBUG) {
  initConfig.errorCaptured = function(err /*, vm, info*/) {
    // TODO: log to 3rd party (Sentry,...)

    this.$store.commit(SET_ERROR, err);
    return false;
  };
}

new Vue(initConfig).$mount('#app');
