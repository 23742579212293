import { applySpec, compose, filter, identity, map, prop, propOr, ifElse, always, allPass, path } from 'ramda';
import { isValidId } from '@/utils/id';

const getValidIdOrNull = compose(ifElse(isValidId, identity, always(null)), propOr('', 'id'));

const activitySpec = applySpec({
  id: getValidIdOrNull,
  title: propOr('', 'title'),
});

const filterById = filter(prop('id'));
const activitiesStateSpec = compose(filterById, map(activitySpec));

const historyRecordSpec = applySpec({
  id: getValidIdOrNull,
  type: propOr(null, 'type'),
  data: propOr(null, 'data'),
  created_at: propOr(null, 'created_at'),
});

const filterByIdAndType = filter(allPass([prop('type'), prop('id'), prop('created_at')]));
const historyStateSpec = compose(filterByIdAndType, map(historyRecordSpec));

const suggestionSpec = applySpec({
  id: getValidIdOrNull,
  activity: compose(activitySpec, propOr({}, 'activity')),
});
const suggestionStateSpec = ifElse(allPass([prop('id'), path(['activity', 'id'])]), identity, always(null));

export const persistedStateSpec = applySpec({
  activities: compose(activitiesStateSpec, propOr([], 'activities')),
  history: compose(historyStateSpec, propOr([], 'history')),
  suggestion: compose(suggestionStateSpec, suggestionSpec, propOr({}, 'suggestion')),
  locale: propOr(process.env.VUE_APP_I18N_LOCALE, 'locale'),
});
