<template>
  <div class="fixed bottom-0 w-full" v-if="message">
    <div class="sm:w-96 sm:mx-auto">
      <div class="font-medium pl-3 pr-2 py-2 flex justify-between items-stretch" v-bind:class="contextClass">
        <div>
          <span v-html="message"></span>

          <button v-on:click="doAction" class="btn btn-sm ml-2 action" v-if="hasAction">
            {{ action.label }}
          </button>
        </div>

        <button v-on:click="close" class="btn btn-sm ml-3 close" v-if="closable">
          <span class="icon-times"></span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Notification',

  props: {
    closable: Boolean,
    message: String,
    context: String,
    action: {
      label: String,
      name: String,
      data: Object,
    },
  },

  computed: {
    hasAction() {
      return this.action && this.action.label && this.action.name;
    },

    contextClass() {
      switch (this.context) {
        case 'error':
        case 'success':
        case 'warning':
        case 'info':
          return this.context;

        default:
          return 'info';
      }
    },
  },

  methods: {
    doAction() {
      this.$store.dispatch(this.action.name, this.action.data);
      this.$notifications.clear();
    },

    close() {
      this.$notifications.clear();
    },
  },
};
</script>

<style scoped>
/* INFO / DEFAULTS */
.style-info {
  @apply bg-indigo-500 text-white border-0 shadow-none;
}

.info {
  @apply style-info;
}

.close {
  @apply style-info hover:bg-indigo-400;
}

/* ERROR */
.style-error {
  @apply bg-red-500 text-white border-0 shadow-none;
}

.error {
  @apply style-error;
}

.error .close {
  @apply style-error hover:bg-red-400;
}

/* SUCCESS: same as info at the moment */
.style-success {
  @apply style-info;
}

.success {
  @apply style-success;
}

/* WARNING: same as info at the moment */
.style-warning {
  @apply style-info;
}

/* .style-warning {
  @apply bg-yellow-300 text-gray-800 border-0 shadow-none;
} */

.warning {
  @apply style-warning;
}

/* .warning .close {
  @apply style-warning hover:bg-yellow-200;
} */
</style>
