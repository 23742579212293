import { REMOVE_ACTIVITY, UNDO_REMOVE_ACTIVITY } from '@/store/action-types';
import {
  ADD_HISTORY_RECORD,
  REMOVE_HISTORY_RECORD,
  REMOVE_ACTIVITY as REMOVE_ACTIVITY_MUTATION,
  ADD_ACTIVITY,
} from '@/store/mutation-types';
import { HISTORY_TYPE_ACTIVITY_REMOVED } from '@/domain/history-types';
import { createId } from '@/utils/id';
import { allPass, filter, head, pathEq, propEq } from 'ramda';
import { EventBus } from '@/event-bus';
import { EVENT_REMOVE_ACTIVITY } from '@/event-types';

export default {
  [REMOVE_ACTIVITY]({ commit }, activity) {
    // log into history
    commit(ADD_HISTORY_RECORD, {
      id: createId(),
      type: HISTORY_TYPE_ACTIVITY_REMOVED,
      data: {
        activity,
      },
      created_at: new Date(),
    });

    // remove activity
    commit(REMOVE_ACTIVITY_MUTATION, activity);

    EventBus.$emit(EVENT_REMOVE_ACTIVITY, activity);
  },

  [UNDO_REMOVE_ACTIVITY]({ commit, state }, activity) {
    // put activity back
    commit(ADD_ACTIVITY, activity);

    // find relevant history record
    var findHistoryRecord = allPass([
      propEq('type', HISTORY_TYPE_ACTIVITY_REMOVED),
      pathEq(['data', 'activity', 'id'], activity.id),
    ]);
    var historyRecord = head(filter(findHistoryRecord)(state.history));

    // remove it from history
    if (historyRecord) {
      commit(REMOVE_HISTORY_RECORD, historyRecord);
    }
  },
};
