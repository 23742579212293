import { EventBus } from '@/event-bus';
import { EVENT_REMOVE_ACTIVITY } from '@/event-types';
import { UNDO_REMOVE_ACTIVITY } from '@/store/action-types';
import { SET_NOTIFICATION } from '@/store/mutation-types';
import store from '@/store/index';

class NotificationsService {
  currentNotificationTimeout;

  // vue-i18n.$t
  i18n;

  constructor() {
    this._setupListeners();
  }

  clear() {
    this._clearTimeout();
    store.commit(SET_NOTIFICATION, null);
  }

  notify(message, context, action, closable = true) {
    this._clearTimeout();

    // create notification
    store.commit(SET_NOTIFICATION, { message, context, action, closable });

    // automatically close this
    if (!closable) {
      this.currentNotificationTimeout = setTimeout(() => {
        store.commit(SET_NOTIFICATION, null);
      }, 5000);
    }
  }

  setI18n(i18n) {
    this.i18n = i18n;
  }

  _clearTimeout() {
    if (this.currentNotificationTimeout) {
      clearTimeout(this.currentNotificationTimeout);
    }
  }

  _setupListeners() {
    EventBus.$on(EVENT_REMOVE_ACTIVITY, (activity) => {
      this.notify(this.i18n.t('view_activities.notification_activity_removed'), 'warning', {
        label: this.i18n.t('global.button_undo'),
        name: UNDO_REMOVE_ACTIVITY,
        data: activity,
        closable: true,
      });
    });
  }
}

export default {
  install(Vue) {
    if (this.installed) {
      return;
    }

    Vue.prototype.$notifications = new NotificationsService();

    this.installed = true;
  },
};
