import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import store from '@/store/index';

Vue.use(VueRouter);

export const Route = {
  HOME: 'Home',
  ADD_ACTIVITY: 'AddActivity',
  VIEW_ACTIVITIES: 'ViewActivities',
  SUGGEST: 'Suggest',
  HISTORY: 'History',
};

const routes = [
  {
    path: '/',
    name: Route.HOME,
    component: Home,
    beforeEnter: (to, from, next) => {
      if (store.state.activities && store.state.activities.length > 2) {
        return next({ name: 'Suggest', replace: true });
      }

      return next({ name: 'AddActivity', replace: true });
    },
  },
  {
    path: '/add-activity',
    name: Route.ADD_ACTIVITY,
    component: () => import(/* webpackChunkName: "add_activity" */ '@/views/AddActivity.vue'),
  },
  {
    path: '/view-activities',
    name: Route.VIEW_ACTIVITIES,
    component: () => import(/* webpackChunkName: "view_activities" */ '@/views/ViewActivities.vue'),
  },
  {
    path: '/suggest',
    name: Route.SUGGEST,
    component: () => import(/* webpackChunkName: "suggest" */ '@/views/Suggest.vue'),
  },
  {
    path: '/history',
    name: Route.HISTORY,
    component: () => import(/* webpackChunkName: "history" */ '@/views/History.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
