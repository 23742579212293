<template>
  <div id="app" class="layout">
    <header class="header">
      <h1 class="title">{{ $t('app.title') }}</h1>
      <p class="subtitle">{{ $t('app.subtitle') }}</p>

      <nav class="nav">
        <router-link to="/suggest">{{ $t('nav.suggest') }}</router-link> |
        <router-link to="/history">{{ $t('nav.history') }}</router-link> |
        <router-link to="/view-activities">{{ $t('nav.view_activities') }}</router-link> |
        <a href="#locale" v-on:click="changeLocale">{{ $t('app.switch_to_language') }}</a>
      </nav>
    </header>

    <Notification
      :message="notification.message"
      :context="notification.context"
      :action="notification.action"
      :closable="notification.closable"
    />

    <router-view />
  </div>
</template>

<script>
import Notification from '@/components/Notification.vue';
import { SET_LOCALE } from '@/store/mutation-types';
import { StoreError } from '@/errors';

export default {
  name: 'App',

  components: {
    Notification,
  },

  beforeMount() {
    // fix iOS Safari layout issue (100vh > visible viewport) with this trick
    // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    if (this.$store.state.locale && this.$i18n.availableLocales.includes(this.$store.state.locale)) {
      this.$i18n.locale = this.$store.state.locale;
    }
  },

  computed: {
    notification() {
      var error = this.$store.state.error;
      if (error) {
        // TODO: disable application in this case
        return {
          message: error instanceof StoreError ? this.$t('store.error_message') : error.message,
          context: 'error',
          closable: false,
        };
      }

      var notification = this.$store.state.notification;
      if (notification) {
        return notification;
      }

      return {};
    },
  },

  methods: {
    changeLocale(event) {
      event.preventDefault();

      // this works only because currently we have 2 available locales
      var locale = 'en';

      if (this.$root.$i18n.locale == 'en') {
        locale = 'cs';
      }

      this.$root.$i18n.locale = locale;
      this.$store.commit(SET_LOCALE, locale);
    },
  },
};
</script>
