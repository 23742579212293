import Vue from 'vue';
import Vuex from 'vuex';
import { createLogger } from 'vuex';
import Storage from '@/store/storage';
import { persistedStateSpec } from '@/store/state-specs';
import mutations from '@/store/mutations';
import actions from '@/store/actions';
import { buildStoreError, StoreError } from '@/errors';

Vue.use(Vuex);

export const STORAGE_KEY = 'whatdo';
export const STORAGE_BACKUP_KEY = 'whatdo_bkp';

const store = new Vuex.Store({
  plugins: [createLogger()],

  state: {
    ...persistedStateSpec({}),
    error: null,
    notification: null,
  },

  mutations,
  actions,
  modules: {},
});

// perstistence in Local Storage
store.subscribe((mutation, state) => {
  try {
    Storage.setItem(STORAGE_KEY, JSON.stringify(persistedStateSpec(state)));

    // TEMP: also store in this storage, for transition period
    // DO NOT IF THERE IS STORE ERROR OR NO ACTIVITIES
    // TODO: check for default state instead of 0 activities
    if ((!state.error || !(state.error instanceof StoreError)) && state.activities.length) {
      Storage.setItem(STORAGE_BACKUP_KEY, JSON.stringify(persistedStateSpec(state)));
    }
  } catch (e) {
    // bubble this up to the global handler
    throw buildStoreError(e);
  }
});

export default store;
